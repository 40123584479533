<template>
  <v-app>
    <v-app-bar app color="white">
      <div style="min-width: 20%" class="d-flex align-center">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
          <v-toolbar-title class="fs-18px">
            {{ $route.meta.pageName }}
          </v-toolbar-title>
        </v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <div v-if="isShowHeaderModule()" class="d-flex">
        <div
          v-for="mod in customerModules"
          :key="`mod_${mod}`"
          class="fs-18px text-center cursor-pointer px-6 font-weight-medium"
          @click="setHeaderModule(mod)"
          :class="{ 'purple--text': isActiveModule(mod) }"
        >
          {{ $t(`labels.${mod}`) }}
        </div>
        <div
          class="fs-18px text-center cursor-pointer px-6 font-weight-medium"
          :class="{ 'purple--text': isActiveRoute('InternalRequest') }"
          @click="redirectToRoute('InternalRequest')"
          v-if="checkPermission(['administration_request'])"
        >
          {{ $t("labels.order_request") }}
        </div>
        <div
          class="fs-18px text-center cursor-pointer px-6 font-weight-medium"
          :class="{ 'purple--text': isActiveRoute('Invoice') }"
          @click="redirectToRoute('Invoice')"
          v-if="checkPermission(['administration_invoice'])"
        >
          {{ $t("labels.invoice") }}
        </div>
      </div>
      <v-spacer></v-spacer>
      <div style="min-width: 20%" class="d-flex align-center justify-end">
        <span>{{ customer.sub_full_name || customer.company_name }}</span>

        <v-menu
          offset-y
          bottom
          left
          :close-on-content-click="false"
          v-model="notifyMenu"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              small
              v-bind="attrs"
              v-on="on"
              class="px-1"
              color="white"
            >
              <v-badge
                bordered
                overlap
                color="error"
                :content="notifyCount > 9 ? '9+' : notifyCount"
                v-if="notifyCount > 0"
              >
                <v-icon>mdi-bell-outline</v-icon>
              </v-badge>
              <v-icon v-else>mdi-bell-outline</v-icon>
            </v-btn>
          </template>
          <v-card
            class="mx-auto"
            max-width="350"
            min-width="300"
            max-height="500"
          >
            <NotifyList v-if="notifyMenu" @onNotifyChange="onNotifyChange" />
          </v-card>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              v-bind="attrs"
              v-on="on"
              small
              color="white"
              class="px-1"
            >
              <img
                v-if="$i18n.locale === 'vi'"
                src="@/assets/sidebars/vietnam.png"
                alt=""
                style="height: 32px"
              />
              <img
                v-else
                src="@/assets/sidebars/usa.png"
                alt=""
                style="height: 32px"
              />
              <v-icon small>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item
              class="cursor-pointer"
              @click="changeLanguage('vi')"
              :class="{ 'primary white--text': $i18n.locale === 'vi' }"
            >
              <v-list-item-title>
                <div class="d-flex align-center">
                  <img
                    src="@/assets/sidebars/vietnam.png"
                    alt=""
                    style="height: 22px"
                    class="mr-1"
                  />
                  <span>Tiếng Việt</span>
                </div>
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              class="cursor-pointer"
              @click="changeLanguage('en')"
              :class="{ 'primary white--text': $i18n.locale === 'en' }"
            >
              <v-list-item-title>
                <div class="d-flex align-center">
                  <img
                    src="@/assets/sidebars/usa.png"
                    alt=""
                    style="height: 22px"
                    class="mr-1"
                  />
                  <span>English</span>
                </div>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              v-bind="attrs"
              v-on="on"
              small
              color="white"
              class="px-0"
              style="min-width: 30px"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <a
              :href="accountLink()"
              class="v-list-item v-list-item--link theme--light"
              tabindex="0"
              role="menuitem"
            >
              <div class="v-list-item__title">
                {{ $t("labels.profile_page") }}
              </div>
            </a>
            <v-list-item @click="showConfigBackground">
              <v-list-item-title>
                {{ $t("labels.set_background") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>{{ $t("labels.logout") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-toolbar elevation="4" light>
        <a href="/">
          <v-img
            height="35"
            contain
            :src="require('@/assets/common/logo.png')"
          ></v-img>
        </a>
      </v-toolbar>

      <v-divider></v-divider>

      <template v-if="crmRows.length > 0">
        <v-subheader class="purple--text">
          {{ $t("labels.crm") }}
        </v-subheader>

        <v-list dense>
          <div v-for="item in crmRows" :key="item.title">
            <template v-if="checkMenuItem(item)">
              <!-- item group -->
              <v-list-group v-if="item.children">
                <template v-slot:prependIcon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </template>
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>

                <!-- item group child -->
                <div v-for="child in item.children" :key="child.title">
                  <v-list-item
                    v-if="checkMenuItem(child)"
                    :to="{ name: child.route }"
                    :disabled="!checkMenuItem(child) || child.disabled"
                  >
                    <!--  -->
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                      <img
                        v-if="child.img"
                        :src="child.img"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon v-if="child.appendIcon">
                        {{ child.appendIcon }}
                      </v-icon>
                      <img
                        v-if="child.appendImg"
                        :src="child.appendImg"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- end item group -->

              <!-- item -->
              <v-list-item
                v-else
                link
                :to="{ name: item.route }"
                @click="checkRoute(item.route)"
                :disabled="!checkMenuItem(item) || item.disabled"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- end item -->
            </template>
          </div>
        </v-list>
      </template>

      <template v-if="warehouseRows.length > 0">
        <v-subheader class="purple--text">
          {{ $t("labels.warehouse") }}
        </v-subheader>

        <v-list dense>
          <div v-for="item in warehouseRows" :key="item.title">
            <template v-if="checkMenuItem(item)">
              <!-- item group -->
              <v-list-group v-if="item.children">
                <template v-slot:prependIcon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </template>
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>

                <!-- item group child -->
                <div v-for="child in item.children" :key="child.title">
                  <v-list-item
                    v-if="checkMenuItem(child)"
                    :to="{ name: child.route }"
                    :disabled="!checkMenuItem(child) || child.disabled"
                  >
                    <!--  -->
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                      <img
                        v-if="child.img"
                        :src="child.img"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon v-if="child.appendIcon">
                        {{ child.appendIcon }}
                      </v-icon>
                      <img
                        v-if="child.appendImg"
                        :src="child.appendImg"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- end item group -->

              <!-- item -->
              <v-list-item
                v-else
                link
                :to="{ name: item.route }"
                @click="checkRoute(item.route)"
                :disabled="!checkMenuItem(item) || item.disabled"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- end item -->
            </template>
          </div>
        </v-list>
      </template>

      <template v-if="posRows.length > 0 && isCustomerActivePos()">
        <v-divider></v-divider>
        <v-subheader class="purple--text">{{ $t("labels.pos") }}</v-subheader>

        <v-list dense>
          <div v-for="item in posRows" :key="item.title">
            <template v-if="checkMenuItem(item)">
              <!-- item group -->
              <v-list-group v-if="item.children">
                <template v-slot:prependIcon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </template>
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>

                <!-- item group child -->
                <div v-for="child in item.children" :key="child.title">
                  <v-list-item
                    v-if="checkMenuItem(child)"
                    :to="{ name: child.route }"
                    :disabled="!checkMenuItem(child) || child.disabled"
                    ><!--  -->
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                      <img
                        v-if="child.img"
                        :src="child.img"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon v-if="child.appendIcon">
                        {{ child.appendIcon }}
                      </v-icon>
                      <img
                        v-if="child.appendImg"
                        :src="child.appendImg"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- end item group -->

              <!-- item -->
              <v-list-item
                v-else
                link
                :to="{ name: item.route }"
                @click="checkRoute(item.route)"
                :disabled="!checkMenuItem(item) || item.disabled"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- end item -->
            </template>
          </div>
        </v-list>
      </template>

      <template v-if="administrationRows.length > 0">
        <v-divider></v-divider>
        <v-subheader class="purple--text">
          {{ $t("labels.administration") }}
        </v-subheader>

        <v-list dense>
          <div v-for="item in administrationRows" :key="item.title">
            <template v-if="checkMenuItem(item)">
              <!-- item group -->
              <v-list-group v-if="item.children">
                <template v-slot:prependIcon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </template>
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>

                <!-- item group child -->
                <div v-for="child in item.children" :key="child.title">
                  <v-list-item
                    v-if="checkMenuItem(child)"
                    :to="{ name: child.route }"
                    :disabled="!checkMenuItem(child) || child.disabled"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                      <img
                        v-if="child.img"
                        :src="child.img"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon v-if="child.appendIcon">
                        {{ child.appendIcon }}
                      </v-icon>
                      <img
                        v-if="child.appendImg"
                        :src="child.appendImg"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- end item group -->

              <!-- item -->
              <v-list-item
                v-else
                link
                :to="{ name: item.route }"
                @click="checkRoute(item.route)"
                :disabled="!checkMenuItem(item) || item.disabled"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- end item -->
            </template>
          </div>
        </v-list>
      </template>

      <template v-if="requestRows.length > 0">
        <v-divider></v-divider>
        <v-subheader class="purple--text">
          {{ $t("labels.order_request") }}
        </v-subheader>

        <v-list dense>
          <div v-for="item in requestRows" :key="item.title">
            <template v-if="checkMenuItem(item)">
              <!-- item group -->
              <v-list-group v-if="item.children">
                <template v-slot:prependIcon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </template>
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>

                <!-- item group child -->
                <div v-for="child in item.children" :key="child.title">
                  <v-list-item
                    v-if="checkMenuItem(child)"
                    :to="{ name: child.route }"
                    :disabled="!checkMenuItem(child) || child.disabled"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                      <img
                        v-if="child.img"
                        :src="child.img"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon v-if="child.appendIcon">
                        {{ child.appendIcon }}
                      </v-icon>
                      <img
                        v-if="child.appendImg"
                        :src="child.appendImg"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- end item group -->

              <!-- item -->
              <v-list-item
                v-else
                link
                :to="{ name: item.route }"
                @click="checkRoute(item.route)"
                :disabled="!checkMenuItem(item) || item.disabled"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- end item -->
            </template>
          </div>
        </v-list>
      </template>

      <template v-if="invoiceRows.length > 0">
        <v-divider></v-divider>
        <v-subheader class="purple--text">
          {{ $t("labels.invoice") }}
        </v-subheader>

        <v-list dense>
          <div v-for="item in invoiceRows" :key="item.title">
            <template v-if="checkMenuItem(item)">
              <!-- item group -->
              <v-list-group v-if="item.children">
                <template v-slot:prependIcon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </template>
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>

                <!-- item group child -->
                <div v-for="child in item.children" :key="child.title">
                  <v-list-item
                    v-if="checkMenuItem(child)"
                    :to="{ name: child.route }"
                    :disabled="!checkMenuItem(child) || child.disabled"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                      <img
                        v-if="child.img"
                        :src="child.img"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon v-if="child.appendIcon">
                        {{ child.appendIcon }}
                      </v-icon>
                      <img
                        v-if="child.appendImg"
                        :src="child.appendImg"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- end item group -->

              <!-- item -->
              <v-list-item
                v-else
                link
                :to="{ name: item.route }"
                @click="checkRoute(item.route)"
                :disabled="!checkMenuItem(item) || item.disabled"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- end item -->
            </template>
          </div>
        </v-list>
      </template>

      <template v-if="officeRows.length > 0">
        <v-divider></v-divider>
        <v-subheader class="purple--text">
          {{ $t("labels.office") }}
        </v-subheader>

        <v-list dense>
          <div v-for="item in officeRows" :key="item.title">
            <template v-if="checkMenuItem(item)">
              <!-- item group -->
              <v-list-group v-if="item.children">
                <template v-slot:prependIcon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </template>
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>

                <!-- item group child -->
                <div v-for="child in item.children" :key="child.title">
                  <v-list-item
                    v-if="checkMenuItem(child)"
                    :to="{ name: child.route }"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                      <img
                        v-if="child.img"
                        :src="child.img"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon v-if="child.appendIcon">
                        {{ child.appendIcon }}
                      </v-icon>
                      <img
                        v-if="child.appendImg"
                        :src="child.appendImg"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- end item group -->

              <!-- item -->
              <v-list-item
                v-else
                link
                :to="{ name: item.route }"
                @click="checkRoute(item.route)"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- end item -->
            </template>
          </div>
        </v-list>
      </template>

      <template v-if="goodsProductionRows.length > 0">
        <v-divider></v-divider>
        <v-subheader class="purple--text">Sản xuất: May</v-subheader>

        <v-list dense>
          <div v-for="item in goodsProductionRows" :key="item.title">
            <template v-if="checkMenuItem(item)">
              <!-- item group -->
              <v-list-group v-if="item.children">
                <template v-slot:prependIcon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </template>
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>

                <!-- item group child -->
                <div v-for="child in item.children" :key="child.title">
                  <v-list-item
                    v-if="checkMenuItem(child)"
                    :to="{ name: child.route }"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                      <img
                        v-if="child.img"
                        :src="child.img"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon v-if="child.appendIcon">
                        {{ child.appendIcon }}
                      </v-icon>
                      <img
                        v-if="child.appendImg"
                        :src="child.appendImg"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- end item group -->

              <!-- item -->
              <v-list-item
                v-else
                link
                :to="{ name: item.route }"
                @click="checkRoute(item.route)"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- end item -->
            </template>
          </div>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-dialog v-model="dialogDashboard" max-width="80vw">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t(`labels.${activeModule}`) }}
        </v-card-title>
        <v-card-text>
          <DashboardItems v-if="dialogDashboard" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="configBackgroundDialog" max-width="360px">
      <ConfigBackground
        @close="hideConfigBackground"
        @setBackground="setBackground"
      />
    </v-dialog>

    <v-dialog v-model="notifyDialog" max-width="400px">
      <v-card>
        <v-card-title class="text-h6">
          {{ $t("labels.notify_type_4") }}
        </v-card-title>
        <v-card-text>
          <NotifyList
            v-if="notifyDialog"
            :is-show-filter="false"
            :default-filter="notifyFilter"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  name: "App",

  components: {
    NotifyList: () => import("@/components/common/NotifyList"),
    DashboardItems: () => import("@/components/common/DashboardItems"),
    ConfigBackground: () => import("@/components/common/ConfigBackground"),
  },

  data: () => ({
    drawer: false,
    items: [],
    posItems: [],
    crmItems: [],
    administrationItems: [],
    requestItems: [],
    invoiceItems: [],
    goodsProductionItems: [],
    officeItems: [],
    customer: {},
    notifyCount: 0,
    notifyDialog: 0,
    notifyFilter: { type: 4, status: 0 },
    notifyMenu: false,
    dialogDashboard: false,
    activeModule: "crm",
    configBackgroundDialog: false,
  }),
  computed: {
    crmRows() {
      const rows = [];
      this.crmItems.forEach((item) => {
        if (this.checkMenuItem(item)) {
          rows.push(item);
        }
      });
      return rows;
    },
    warehouseRows() {
      const rows = [];
      this.items.forEach((item) => {
        if (this.checkMenuItem(item)) {
          rows.push(item);
        }
      });
      return rows;
    },
    posRows() {
      const rows = [];
      this.posItems.forEach((item) => {
        if (this.checkMenuItem(item)) {
          rows.push(item);
        }
      });
      return rows;
    },
    administrationRows() {
      const rows = [];
      const sortedList = [...this.administrationItems].sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      sortedList.forEach((item) => {
        if (this.checkMenuItem(item)) {
          rows.push(item);
        }
      });
      return rows;
    },
    requestRows() {
      const rows = [];
      const sortedList = [...this.requestItems].sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      sortedList.forEach((item) => {
        if (this.checkMenuItem(item)) {
          rows.push(item);
        }
      });
      return rows;
    },
    invoiceRows() {
      const rows = [];
      const sortedList = [...this.invoiceItems].sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      sortedList.forEach((item) => {
        if (this.checkMenuItem(item)) {
          rows.push(item);
        }
      });
      return rows;
    },
    goodsProductionRows() {
      const rows = [];
      this.goodsProductionItems.forEach((item) => {
        if (this.checkMenuItem(item)) {
          rows.push(item);
        }
      });
      return rows;
    },
    officeRows() {
      const rows = [];
      this.officeItems.forEach((item) => {
        if (this.checkMenuItem(item)) {
          rows.push(item);
        }
      });
      return rows;
    },
    customerModules() {
      const modules = [];
      if (this.crmRows.length > 0) {
        modules.push("crm");
      }
      if (this.warehouseRows.length > 0) {
        modules.push("warehouse");
      }
      if (this.posRows.length > 0 && this.isCustomerActivePos()) {
        modules.push("pos");
      }
      if (this.administrationRows.length > 0) {
        modules.push("administration");
      }
      if (this.goodsProductionRows.length > 0) {
        modules.push("goods_production");
      }
      if (this.officeRows.length > 0) {
        modules.push("office");
      }
      return modules;
    },
  },
  watch: {
    $route() {
      if (this.dialogDashboard) {
        this.dialogDashboard = false;
      }
    },
  },
  created() {
    this.crmItems = [
      {
        title: this.$t("labels.lead_create"),
        img: require("@/assets/sidebars/receipt_create.png"),
        route: "CrmLeadCreate",
        active: false,
        permissions: ['crm_lead_create'],
      },
    ];
    this.items = [
      {
        title: this.$t("labels.receipt_create"),
        img: require("@/assets/sidebars/receipt_create.png"),
        route: "GoodsReceiptCreate",
        active: false,
        permissions: ["goods_receipt_create"],
      },
      {
        title: this.$t("labels.return_history"),
        img: require("@/assets/sidebars/return_history.png"),
        route: "GoodsReturnHistory",
        active: false,
        permissions: ["goods_return_history"],
      },
      {
        title: this.$t("labels.create_order_single"),
        img: require("@/assets/sidebars/create_order_single.png"),
        route: "OrderCreateSingle",
        active: false,
        permissions: ["order_create_single"],
      },
      {
        title: this.$t("labels.create_order_excel"),
        img: require("@/assets/sidebars/create_order_excel.png"),
        route: "OrderCreateExcel",
        active: false,
        permissions: ["order_create_excel"],
      },
      {
        title: this.$t("labels.create_order_e_market"),
        img: require("@/assets/sidebars/create_order_e_market.png"),
        route: "OrderCreateEMarket",
        active: false,
        permissions: ["order_create_e_market"],
      },
      {
        title: this.$t("labels.goods_list"),
        img: require("@/assets/sidebars/goods_list.png"),
        route: "GoodsList",
        active: false,
        permissions: ["goods_manager"],
      },
      {
        title: this.$t("labels.order_list"),
        img: require("@/assets/sidebars/order_list.png"),
        route: "OrderList",
        active: false,
        permissions: ["order_manager"],
      },
      {
        title: this.$t("labels.fast_report"),
        img: require("@/assets/sidebars/thongkenhanh.png"),
        route: "OrderReport",
        permissions: ["order_dashboard_manager"],
      },
      {
        title: this.$t("labels.goods_transfer"),
        img: require("@/assets/sidebars/pos_chuyen_noi_bo.png"),
        route: "GoodsTransfer",
        permissions: ["goods_transfer"],
      },
      {
        title: this.$t("labels.employee_warning"),
        img: require("@/assets/sidebars/pos_nhap_kiem.png"),
        route: "EmployeeWarning",
        active: false,
        permissions: ["warehouse_employee_warning"],
      },
    ];

    this.posItems = [
      {
        title: this.$t("labels.pos_goods_receipt"),
        img: require("@/assets/sidebars/pos_nhap_kiem.png"),
        route: "PosGoodsReceiptCreate",
        permissions: ["pos_goods_receipt_create"],
      },
      {
        title: this.$t("labels.goods_transfer"),
        img: require("@/assets/sidebars/pos_chuyen_noi_bo.png"),
        route: "PosGoodsTransfer",
        permissions: ["pos_goods_transfer"],
      },
      {
        title: this.$t("labels.create_order_online"),
        img: require("@/assets/sidebars/create_order_single.png"),
        route: "PosOrderCreateSingle",
        active: false,
        permissions: ["pos_order_create_single"],
      },
      {
        title: this.$t("labels.goods_list"),
        route: "PosGoodsList",
        img: require("@/assets/sidebars/goods_list.png"),
        permissions: ["pos_goods_manager"],
      },
      {
        title: this.$t("labels.pos_order_report"),
        route: "PosOrderList",
        img: require("@/assets/sidebars/pos_quan_ly_don.png"),
        permissions: ["pos_order_list"],
      },
      {
        title: this.$t("labels.order_online_list"),
        route: "PosOrderOnlineList",
        img: require("@/assets/sidebars/order_list.png"),
        permissions: ["pos_order_online_list"],
      },
    ];

    this.administrationItems = [
      {
        title: this.$t("labels.import_management"),
        img: require("@/assets/sidebars/receipt_history.png"),
        route: "AdministrationReceiptHistory",
        active: false,
        permissions: ["administration_receipt_history"],
      },
      {
        title: this.$t("labels.business_results"),
        img: require("@/assets/sidebars/tai_chinh.png"),
        route: "AdministrationReportList",
        active: false,
        permissions: ["administration_report_revenue"],
      },
      {
        title: this.$t("labels.delivery_speed"),
        img: require("@/assets/sidebars/vanchuyen.png"),
        route: "AdministrationGoodsReport",
        active: false,
        permissions: ["administration_goods_report"],
      },
      {
        title: this.$t("labels.sale_price"),
        img: require("@/assets/sidebars/goods_price.png"),
        route: "AdministrationGoodsPrice",
        active: false,
        permissions: ["administration_goods_price"],
      },
      {
        title: this.$t("labels.cost_price"),
        img: require("@/assets/sidebars/goods_cost.png"),
        route: "AdministrationGoodsCost",
        active: false,
        permissions: ["administration_goods_cost"],
      },
      {
        title: this.$t("labels.supplier"),
        img: require("@/assets/sidebars/supplier.png"),
        route: "AdministrationSupplier",
        active: false,
        permissions: ["administration_supplier"],
      },
      {
        title: this.$t("labels.supplies"),
        img: require("@/assets/sidebars/supplies.png"),
        route: "AdministrationSupply",
        active: false,
        permissions: ["administration_supply"],
      },
      {
        title: this.$t("labels.category"),
        img: require("@/assets/sidebars/danhmuc.png"),
        route: "AdministrationGoodsCategory",
        active: false,
        permissions: ["administration_goods_category"],
      },
      {
        title: this.$t("labels.profile"),
        img: require("@/assets/sidebars/hoso.png"),
        route: "AdministrationGoodsProfile",
        active: false,
        permissions: ["administration_goods_profile"],
      },
      {
        title: this.$t("labels.pos_goods_display_request"),
        img: require("@/assets/sidebars/pos_trung_bay.png"),
        route: "AdministrationPosGoodsDisplayRequest",
        permissions: ["administration_pos_goods_display_request"],
      },
      {
        title: this.$t("labels.goods_missing"),
        img: require("@/assets/sidebars/confused.png"),
        route: "AdministrationGoodsMissing",
        active: false,
        permissions: ["administration_goods_missing"],
      },
      {
        title: this.$t("labels.promotion"),
        img: require("@/assets/sidebars/pos_chuong_trinh.png"),
        active: false,
        permissions: [
          "administration_pos_promotion_config",
          "administration_pos_accrue_point_config",
          "administration_pos_voucher_config",
        ],
        children: [
          {
            title: this.$t("labels.pos_promotion"),
            route: "AdministrationPosPromotionConfig",
            appendImg: require("@/assets/sidebars/pos_khuyen_mai.png"),
            permissions: ["administration_pos_promotion_config"],
          },
          {
            title: this.$t("labels.pos_accrue_point"),
            route: "AdministrationPosAccruePointConfig",
            appendImg: require("@/assets/sidebars/pos_tich_diem.png"),
            permissions: ["administration_pos_accrue_point_config"],
          },
          {
            title: this.$t("labels.pos_voucher"),
            route: "AdministrationPosVoucherConfig",
            appendImg: require("@/assets/sidebars/pos_ma_giam_gia.png"),
            permissions: ["administration_pos_voucher_config"],
          },
        ],
      },
      {
        title: this.$t("labels.zns_history"),
        img: require("@/assets/sidebars/zns.png"),
        route: "AdministrationZns",
        active: false,
        permissions: ["administration_zns"],
      },
      {
        title: this.$t("labels.goods_debt"),
        img: require("@/assets/sidebars/debt.png"),
        route: "AdministrationGoodsDebt",
        active: false,
        permissions: ["administration_goods_debt"],
      },
      {
        title: this.$t("labels.present"),
        route: "AdministrationPresent",
        img: require("@/assets/sidebars/gift.png"),
        active: false,
        permissions: ["administration_present"],
      },
      {
        title: this.$t("labels.physical_combo_program"),
        route: "AdministrationPhysicalCombo",
        img: require("@/assets/sidebars/physical_combo.png"),
        active: false,
        permissions: ["administration_physical_combo"],
      },
      {
        title: this.$t("labels.delivery_shop"),
        route: "DeliveryShopConfig",
        img: require("@/assets/sidebars/tracking.png"),
        active: false,
        permissions: ["administration_delivery_shop"],
      },
      {
        title: this.$t("labels.priority_goods_quantity"),
        route: "AdministrationPriorityGoodsQuantity",
        img: require("@/assets/sidebars/multi_warehouse_priority.png"),
        active: false,
        permissions: ["administration_priority_goods_quantity"],
      },
    ];

    this.requestItems = [
      {
        title: this.$t("labels.list"),
        img: require("@/assets/sidebars/order_request.png"),
        route: "InternalRequest",
        active: false,
        permissions: ["administration_request"],
      },
    ];

    this.invoiceItems = [
      {
        title: this.$t("labels.invoice"),
        img: require("@/assets/sidebars/hoso.png"),
        route: "Invoice",
        active: false,
        permissions: ["administration_invoice"],
      },
    ];

    this.goodsProductionItems = [
      {
        title: this.$t("labels.product_profile"),
        img: require("@/assets/sidebars/sx_hososanpham.png"),
        route: "GoodsProductionRecord",
        active: false,
        permissions: ["goods_production_record"],
      },
      {
        title: this.$t("labels.warehouse_receipt_history"),
        img: require("@/assets/sidebars/receipt_history.png"),
        route: "MaterialReceiptHistory",
        active: false,
        permissions: ["material_receipt_history"],
      },
      {
        title: this.$t("labels.production_request"),
        img: require("@/assets/sidebars/sx_ycsx.png"),
        route: "GoodsProductionRequirement",
        active: false,
        permissions: ["goods_production_requirement"],
      },
      {
        title: this.$t("labels.quantitative_standard"),
        img: require("@/assets/sidebars/sx_dinhmuc.png"),
        route: "GoodsProductionQuantitative",
        active: false,
        permissions: ["goods_production_quantitative"],
      },
    ];

    this.officeItems = [
      {
        title: this.$t("labels.timekeeping"),
        img: require("@/assets/sidebars/timetable.png"),
        route: "OfficeTimekeepingCheck",
        active: false,
        permissions: ["office_timekeeping"],
      },
    ];
  },
  mounted() {
    window.socket.on("auth", (data) => {
      console.log(data);
    });
    window.socket.emit("auth", window.me.auth_key, "customer");
    window.exportWaiting = false;

    const { customer, display_name, identity_id, notify } = window.me;
    this.customer = { ...customer, display_name, identity_id };
    this.notifyCount = notify;
    const selectedImage = localStorage.getItem("sib") || null;
    this.setBackground(selectedImage);
    this.listenSocket();
    this.getTokenExpiredNotify();
  },
  beforeDestroy() {},
  methods: {
    getTokenExpiredNotify: debounce(async function () {
      if (!this.notifyCount) {
        return false;
      }
      httpClient.post("/notifications", this.notifyFilter).then(({ data }) => {
        if (data.total > 0) {
          this.notifyDialog = true;
        }
      });
    }, 500),
    setBackground(url) {
      console.log(url);
      if (!url) {
        document.getElementById("app").style.backgroundImage = "";
      } else {
        document.getElementById("app").style.backgroundImage = `url("${url}")`;
      }
    },
    showConfigBackground() {
      this.configBackgroundDialog = true;
    },
    hideConfigBackground() {
      this.configBackgroundDialog = false;
    },
    checkMenuItem(item) {
      return (
        (!item.permissions ||
          item.permissions.length === 0 ||
          this.checkPermission(item.permissions)) &&
        this.checkMenuMobile(item.mobileCheck) &&
        this.checkMenuDesktop(item.desktopCheck)
      );
    },
    async logout() {
      await httpClient.post("/logout");
      localStorage.removeItem("_sct");
      window.location = process.env.VUE_APP_V2_HOME_PATH;
      return true;
    },
    checkRoute(routeName) {
      if (this.$route.name === routeName) {
        window.location.reload();
      }
    },
    accountLink() {
      // return '/account-management'
      if (this.customer.identity_id === this.customer.id_identity) {
        return "/account";
      } else {
        return "/change-password";
      }
    },
    onNotifyChange(count) {
      this.notifyCount = count;
    },

    listenSocket() {
      const self = this;
      /* window.socket.on('notification_customer', data => {
                self.notifyCount += 1
                self.$vToastify.warning(this.$t('messages.new_noti', {title:data.title }));
            }) */
      window.socket.on("rt_export_request_identity", (data) => {
        if (window.exportWaiting) {
          window.exportWaiting = false;
          const filenameSplit = data.filename.split("/");
          const filename = filenameSplit[filenameSplit.length - 1];
          self.downloadExcelFile("/download-export-excel-file", data, filename);
        }
      });
    },
    setHeaderModule(mod) {
      this.activeModule = mod;
      const isDashboard = this.checkActiveRoute("Dashboard");
      if (!isDashboard) {
        this.dialogDashboard = true;
      }
      setTimeout(() => {
        this.$root.$emit("setActiveModule", this.activeModule);
      }, 100);
    },
    isActiveModule(mod) {
      const isDashboard = this.checkActiveRoute("Dashboard");
      if (isDashboard) {
        return mod === this.activeModule;
      } else {
        const routeModules = this.$route.meta.modules;
        let isActive = false;
        if (routeModules && routeModules.length > 0) {
          routeModules.forEach((rMod) => {
            if (rMod.includes(`${mod}_`)) {
              isActive = true;
            }
          });
        } else {
          isActive = this.$route.path.includes(mod);
        }

        return isActive;
      }
    },
    isShowHeaderModule() {
      return !this.isMobileAgent() && !this.checkActiveRoute("Account");
    },
    isActiveRoute(name) {
      return this.$route.name == name;
    },
    changeLanguage(lang) {
      localStorage.setItem("_lang", lang);
      window.location.reload();
    },
  },
};
</script>
