<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.confirm") }}
    </v-card-title>
    <v-card-text>
      {{ $t("labels.send_otp_to_zalo") }}
    </v-card-text>

    <v-card-text>
      <v-row dense>
        <v-col v-for="(code, index) in codes" :key="`c_${index}`">
          <v-text-field
            v-model="codes[index]"
            class="c-input-otp"
            type="number"
            max="9"
            min="0"
            dense
            single-line
            outlined
            hide-details
            v-mask="'#'"
            :ref="`inputOtp_${index}`"
            :autofocus="index === 0"
            @keyup="onInputChanged(index)"
            @paste.prevent.stop="onOtpPaste"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!isAllowConfirm"
        color="error darken-1"
        text
        @click="sendOtp"
        :disabled="countDown > 0"
      >
        {{ $t("labels.resend") }}
        <span v-if="countDown > 0"> ({{ countDown }}s) </span>
      </v-btn>
      <v-btn v-else color="success darken-1" text @click="confirm">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "Otp",
  props: {
    phoneNumber: {
      type: String,
      default: () => "",
    },
  },
  data: () => ({
    codes: ["", "", "", "", "", ""],
    countDown: 0,
    isLoading: false,
  }),
  computed: {
    isAllowConfirm() {
      const checkEmpty = [...this.codes].filter(
        (code) => code === null || code === ""
      );
      if (checkEmpty && checkEmpty.length > 0) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.sendOtp();
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },

    countdownForResend() {
      if (!this.phoneNumber) {
        this.$vToastify.error(this.$t("messages.wrong_phone"));
        return false;
      }
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countdownForResend();
        }, 1000);
      }
    },

    onInputChanged(index) {
      if (
        index > 0 &&
        (this.codes[index] === null || this.codes[index] === "")
      ) {
        const ref = `inputOtp_${index - 1}`;
        this.$refs[ref][0].focus();
        return true;
      }

      if (index < this.codes.length - 1 && this.codes[index]) {
        const ref = `inputOtp_${index + 1}`;
        this.$refs[ref][0].focus();
        return true;
      }

      if (index === this.codes.length - 1) {
        this.confirm();
      }
    },

    isNumber(value) {
      return /^-?\d*$/.test(value);
    },

    onOtpPaste(evt) {
      let value = evt.clipboardData.getData("text");
      value = value.trim();
      if (value.length !== this.codes.length) {
        return false;
      }

      if (!this.isNumber(value)) {
        return false;
      }

      this.codes = value.split("");

      return this.confirm();
    },

    async sendOtp() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/send-otp", {
          phone_number: this.phoneNumber,
        });
        this.isLoading = false;
        this.countDown = 60;
        this.countdownForResend();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async confirm() {
      if (!this.isAllowConfirm) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }

      this.isLoading = true;

      try {
        await httpClient.post("/submit-otp", {
          phone_number: this.phoneNumber,
          code: this.codes.join(""),
        });
        this.isLoading = false;
        this.$emit("success");
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
